.faq-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
}

.faq-container h1 {
    color: var(--text-primary);
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    padding-bottom: 15px;
}

.faq-container h1::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    width: 100px;
    height: 3px;
    background: linear-gradient(90deg, transparent, var(--primary-main), transparent);
}

.faq-content {
    display: grid;
    gap: 15px;
}

.faq-item {
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: all 0.3s ease;
}

.faq-item:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.faq-question {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    color: var(--text-primary);
}

.faq-icon {
    font-size: 1.5rem;
    color: var(--primary-main);
    transition: transform 0.3s ease;
}

.faq-answer {
    padding: 0 20px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    background-color: var(--background-default);
    color: var(--text-secondary);
    line-height: 1.6;
}

.faq-item.open .faq-answer {
    padding: 20px;
    max-height: 500px;
    border-top: 1px solid var(--border-light);
}

.faq-item.open .faq-icon {
    transform: rotate(180deg);
}

@media (max-width: 768px) {
    .faq-container {
        margin: 20px auto;
        padding: 15px;
    }

    .faq-question {
        padding: 15px;
        font-size: 0.95rem;
    }

    .faq-item.open .faq-answer {
        padding: 15px;
    }
} 