.preview-step {
    background-color: #fff;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.preview-content {
    margin: 2rem 0;
}

.preview-section {
    margin-bottom: 2rem;
    padding: 1.5rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #e9ecef;
}

.preview-section h3 {
    color: #2c3e50;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #e9ecef;
}

.preview-field {
    display: flex;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;
}

.preview-field strong {
    min-width: 150px;
    color: #495057;
}

.total-amount {
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: 2px dashed #dee2e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
}

.preview-actions {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.back-button,
.print-button,
.confirm-button {
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-weight: bold;
    transition: all 0.3s ease;
}

.back-button {
    background-color: #6c757d;
    color: white;
}

.print-button {
    background-color: #0056b3;
    color: white;
}

.confirm-button {
    background-color: #28a745;
    color: white;
}

.back-button:hover { background-color: #5a6268; }
.print-button:hover { background-color: #004494; }
.confirm-button:hover { background-color: #218838; }

.skip-payment-button {
    background-color: #805ad5;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-weight: bold;
    transition: all 0.3s ease;
}

.skip-payment-button:hover {
    background-color: #6b46c1;
}

@media print {
    .preview-actions {
        display: none;
    }

    .preview-step {
        box-shadow: none;
        padding: 0;
    }

    .preview-section {
        break-inside: avoid;
        border: 1px solid #000;
    }
}

@media (max-width: 768px) {
    .preview-actions {
        flex-direction: column;
    }

    .preview-field {
        flex-direction: column;
    }

    .preview-field strong {
        margin-bottom: 0.25rem;
    }

    .skip-payment-button {
        width: 100%;
    }
} 