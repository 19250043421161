.footer {
    background: linear-gradient(135deg, #1a365d 0%, #2B6CB0 100%);
    color: white;
    padding: 60px 0 20px;
    margin-top: 60px;
    position: relative;
    overflow: hidden;
}

/* Decorative wave effect at the top */
.footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(-45deg, transparent 33.33%, #fff 33.33%, #fff 66.66%, transparent 66.66%),
                linear-gradient(45deg, transparent 33.33%, #fff 33.33%, #fff 66.66%, transparent 66.66%);
    background-size: 30px 20px;
    background-repeat: repeat-x;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 60px;
    position: relative;
}

.footer-section {
    display: flex;
    flex-direction: column;
}

.footer-section h3 {
    color: white;
    margin-bottom: 25px;
    font-size: 1.25rem;
    position: relative;
    padding-bottom: 15px;
    font-weight: 600;
}

.footer-section h3::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #4299E1, transparent);
    border-radius: 2px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.footer-section ul li {
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer-section ul li::before {
    content: '•';
    color: #4299E1;
}

.footer-section ul li a {
    color: #E2E8F0;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    padding: 2px 0;
}

.footer-section ul li a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #4299E1;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
}

.footer-section ul li a:hover {
    color: #4299E1;
}

.footer-section ul li a:hover::after {
    transform: scaleX(1);
}

.footer-section p {
    color: #E2E8F0;
    line-height: 1.8;
    text-align: justify;
}

.footer-bottom {
    max-width: 1200px;
    margin: 40px auto 0;
    padding: 20px 40px;
    text-align: center;
    position: relative;
}

.footer-bottom::before {
    content: '';
    position: absolute;
    top: 0;
    left: 40px;
    right: 40px;
    height: 1px;
    background: linear-gradient(90deg, 
        transparent, 
        rgba(255, 255, 255, 0.1) 20%, 
        rgba(255, 255, 255, 0.2) 50%, 
        rgba(255, 255, 255, 0.1) 80%, 
        transparent
    );
}

.footer-bottom p {
    color: #E2E8F0;
    margin: 0;
    font-size: 0.9rem;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .footer {
        padding: 40px 0 20px;
        margin-top: 40px;
    }

    .footer-content {
        grid-template-columns: 1fr;
        gap: 40px;
        padding: 0 20px;
    }

    .footer-section {
        text-align: center;
    }

    .footer-section h3 {
        margin-bottom: 20px;
    }

    .footer-section h3::after {
        right: 50%;
        transform: translateX(50%);
        width: 100px;
    }

    .footer-section ul li {
        justify-content: center;
    }

    .footer-section p {
        text-align: center;
        padding: 0 10px;
    }

    .footer-bottom {
        padding: 20px;
        margin-top: 30px;
    }

    .footer-bottom::before {
        left: 20px;
        right: 20px;
    }
}

/* Tablet Responsive */
@media (min-width: 769px) and (max-width: 1024px) {
    .footer-content {
        grid-template-columns: repeat(2, 1fr);
        padding: 0 30px;
    }

    .footer-section:last-child {
        grid-column: span 2;
    }
}

/* Print styles */
@media print {
    .footer {
        display: none;
    }
} 