.tracking-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tracking-form {
    margin: 20px 0;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
}

.tracking-form .form-group {
    margin-bottom: 20px;
}

.tracking-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.tracking-form input {
    width: 100%;
    padding: 12px;
    border: 2px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
}

.tracking-form button {
    background-color: var(--primary-main);
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    width: 100%;
}

.order-status {
    margin-top: 30px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #e9ecef;
}

.status-details {
    display: grid;
    gap: 15px;
}

.status-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #dee2e6;
}

.status-message {
    margin-top: 20px;
    padding: 15px;
    background-color: #e8f5e9;
    border-radius: 6px;
    color: #2e7d32;
}

.error-message {
    margin-top: 20px;
    padding: 15px;
    background-color: #ffebee;
    border-radius: 6px;
    color: #c62828;
    text-align: center;
}

@media (max-width: 768px) {
    .tracking-container {
        margin: 20px;
        padding: 15px;
    }

    .status-item {
        flex-direction: column;
        gap: 5px;
    }
} 