.rules-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
}

.rules-container h1 {
    color: var(--text-primary);
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    padding-bottom: 15px;
}

.rules-container h1::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    width: 100px;
    height: 3px;
    background: linear-gradient(90deg, transparent, var(--primary-main), transparent);
}

.rules-content {
    display: grid;
    gap: 30px;
}

.rules-section {
    background-color: white;
    border-radius: 12px;
    padding: 25px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.rules-section h2 {
    color: var(--primary-main);
    margin-bottom: 20px;
    font-size: 1.3rem;
    position: relative;
    padding-right: 15px;
}

.rules-section h2::before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 20px;
    background-color: var(--primary-main);
    border-radius: 2px;
}

.rules-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.rules-section ul li {
    margin-bottom: 15px;
    padding-right: 20px;
    position: relative;
    line-height: 1.6;
}

.rules-section ul li::before {
    content: '•';
    color: var(--primary-main);
    position: absolute;
    right: 0;
    top: 0;
}

@media (max-width: 768px) {
    .rules-container {
        margin: 20px auto;
        padding: 15px;
    }

    .rules-section {
        padding: 20px;
    }
} 