.affiliate-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
}

.affiliate-container h1 {
    color: var(--text-primary);
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    padding-bottom: 15px;
}

.affiliate-container h1::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    width: 100px;
    height: 3px;
    background: linear-gradient(90deg, transparent, var(--primary-main), transparent);
}

.affiliate-section {
    background-color: white;
    border-radius: 12px;
    padding: 30px;
    margin-bottom: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.affiliate-section h2 {
    color: var(--primary-main);
    margin-bottom: 25px;
    font-size: 1.5rem;
    position: relative;
    padding-right: 15px;
}

.affiliate-section h2::before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 25px;
    background-color: var(--primary-main);
    border-radius: 2px;
}

/* Opportunity Cards */
.opportunity-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.opportunity-card {
    background-color: var(--background-default);
    padding: 25px;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.opportunity-card:hover {
    transform: translateY(-5px);
}

.opportunity-card h3 {
    color: var(--primary-dark);
    margin-bottom: 15px;
    font-size: 1.2rem;
}

.opportunity-card ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.opportunity-card ul li {
    margin-bottom: 10px;
    padding-right: 20px;
    position: relative;
}

.opportunity-card ul li::before {
    content: '✓';
    color: var(--success-main);
    position: absolute;
    right: 0;
    font-weight: bold;
}

/* Benefits Grid */
.benefits-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.benefit-item {
    text-align: center;
    padding: 20px;
    background-color: var(--background-default);
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.benefit-item:hover {
    transform: translateY(-5px);
}

.benefit-icon {
    font-size: 2.5rem;
    margin-bottom: 15px;
    display: block;
}

.benefit-item h4 {
    color: var(--primary-dark);
    margin-bottom: 10px;
}

.benefit-item p {
    color: var(--text-secondary);
    line-height: 1.6;
}

/* Requirements List */
.requirements-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
}

.requirements-list ul li {
    padding-right: 25px;
    position: relative;
    color: var(--text-primary);
}

.requirements-list ul li::before {
    content: '•';
    color: var(--primary-main);
    position: absolute;
    right: 0;
    font-size: 1.5rem;
    line-height: 1;
}

/* Contact Form */
.affiliate-form {
    max-width: 600px;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: var(--text-primary);
    font-weight: 500;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 12px;
    border: 2px solid var(--border-main);
    border-radius: 8px;
    font-family: inherit;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    border-color: var(--primary-main);
    outline: none;
    box-shadow: 0 0 0 3px rgba(43, 108, 176, 0.1);
}

.submit-button {
    width: 100%;
    padding: 14px;
    background-color: var(--primary-main);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.submit-button:hover {
    background-color: var(--primary-dark);
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .affiliate-container {
        padding: 15px;
    }

    .affiliate-section {
        padding: 20px;
    }

    .opportunity-cards,
    .benefits-grid {
        grid-template-columns: 1fr;
    }

    .requirements-list ul {
        grid-template-columns: 1fr;
    }
}

/* Add these new styles */
.highlight-section {
    background: linear-gradient(135deg, var(--primary-light) 0%, var(--primary-main) 100%);
    color: white;
}

.highlight-section h2 {
    color: white;
}

.highlight-section h2::before {
    background-color: white;
}

.referral-info {
    display: flex;
    gap: 30px;
    align-items: center;
    margin-top: 20px;
}

.referral-highlight {
    text-align: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    flex: 0 0 200px;
}

.percentage {
    font-size: 3rem;
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
}

.referral-features {
    flex: 1;
}

.referral-features ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.referral-features ul li {
    margin-bottom: 10px;
    padding-right: 25px;
    position: relative;
    color: white;
}

.referral-features ul li::before {
    content: '✓';
    position: absolute;
    right: 0;
    color: white;
}

.how-it-works {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.step-item {
    text-align: center;
    padding: 20px;
    background-color: var(--background-default);
    border-radius: 10px;
    position: relative;
}

.step-number {
    width: 40px;
    height: 40px;
    background-color: var(--primary-main);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin: 0 auto 15px;
}

.referral-link-section {
    background-color: var(--background-default);
}

.referral-link-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.referral-link-container input {
    flex: 1;
    padding: 12px;
    border: 2px solid var(--border-main);
    border-radius: 8px;
    font-family: inherit;
    background-color: white;
    color: var(--text-primary);
}

.copy-button {
    padding: 12px 24px;
    background-color: var(--primary-main);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.copy-button:hover {
    background-color: var(--primary-dark);
}

.referral-note {
    margin-top: 15px;
    color: var(--text-secondary);
    font-size: 0.9rem;
}

@media (max-width: 768px) {
    .referral-info {
        flex-direction: column;
    }

    .referral-highlight {
        width: 100%;
    }

    .referral-link-container {
        flex-direction: column;
    }

    .copy-button {
        width: 100%;
    }
}

.payment-info {
    background-color: var(--background-default);
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
}

.payment-info ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
}

.payment-info ul li {
    padding-right: 25px;
    position: relative;
    color: var(--text-primary);
}

.payment-info ul li::before {
    content: '💰';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

@media (max-width: 768px) {
    .payment-info ul {
        grid-template-columns: 1fr;
    }
}

.dashboard-section {
    margin-top: 30px;
}

.dashboard-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}

.stat-item {
    background-color: var(--background-default);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.stat-label {
    display: block;
    color: var(--text-secondary);
    margin-bottom: 10px;
    font-size: 0.9rem;
}

.stat-value {
    display: block;
    color: var(--primary-main);
    font-size: 1.5rem;
    font-weight: bold;
}

.referrals-list {
    margin-top: 30px;
}

.referrals-list h3 {
    margin-bottom: 20px;
    color: var(--text-primary);
}

.referrals-list table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
}

.referrals-list th,
.referrals-list td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid var(--border-main);
}

.referrals-list th {
    background-color: var(--background-default);
    font-weight: 600;
    color: var(--text-primary);
}

.referrals-list tr:last-child td {
    border-bottom: none;
}

.no-referrals {
    text-align: center;
    color: var(--text-secondary);
    padding: 30px;
    background-color: var(--background-default);
    border-radius: 8px;
}

@media (max-width: 768px) {
    .dashboard-stats {
        grid-template-columns: 1fr;
    }

    .referrals-list {
        overflow-x: auto;
    }

    .referrals-list table {
        min-width: 600px;
    }
}

/* Add these styles for the intro section */
.affiliate-header {
    text-align: center;
    padding: 60px 20px;
    background: linear-gradient(135deg, var(--primary-main) 0%, var(--primary-dark) 100%);
    color: white;
    border-radius: 16px;
    margin-bottom: 40px;
    position: relative;
    overflow: hidden;
}

.affiliate-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 20% 150%, rgba(255,255,255,0.1) 0%, transparent 50%);
}

.affiliate-header h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    position: relative;
}

.affiliate-header p {
    font-size: 1.2rem;
    opacity: 0.9;
    max-width: 600px;
    margin: 0 auto;
}

.affiliate-intro {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
}

.intro-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 50px;
}

.stat-card {
    background: white;
    padding: 30px;
    border-radius: 16px;
    text-align: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    position: relative;
    overflow: hidden;
}

.stat-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, var(--primary-light), var(--primary-main));
    transform: scaleX(0);
    transition: transform 0.3s ease;
    transform-origin: left;
}

.stat-card:hover {
    transform: translateY(-10px);
}

.stat-card:hover::before {
    transform: scaleX(1);
}

.big-percentage {
    font-size: 3.5rem;
    font-weight: bold;
    color: var(--primary-main);
    display: block;
    margin-bottom: 15px;
    line-height: 1;
}

.big-number {
    font-size: 3.5rem;
    font-weight: bold;
    color: var(--primary-main);
    display: block;
    margin-bottom: 15px;
    line-height: 1;
}

.big-text {
    font-size: 3.5rem;
    font-weight: bold;
    color: var(--primary-main);
    display: block;
    margin-bottom: 15px;
    line-height: 1;
}

.stat-card p {
    color: var(--text-secondary);
    font-size: 1.1rem;
}

.intro-actions {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 40px;
}

.start-button {
    padding: 15px 40px;
    background: linear-gradient(135deg, var(--primary-main), var(--primary-dark));
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(43, 108, 176, 0.2);
}

.start-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(43, 108, 176, 0.3);
}

.dashboard-button {
    padding: 15px 40px;
    background: white;
    color: var(--primary-main);
    border: 2px solid var(--primary-main);
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.dashboard-button:hover {
    background: var(--primary-main);
    color: white;
    transform: translateY(-3px);
}

@media (max-width: 768px) {
    .affiliate-header {
        padding: 40px 20px;
        border-radius: 0;
    }

    .affiliate-header h1 {
        font-size: 1.8rem;
    }

    .affiliate-header p {
        font-size: 1rem;
    }

    .intro-stats {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .intro-actions {
        flex-direction: column;
    }

    .start-button,
    .dashboard-button {
        width: 100%;
    }
}

/* Add animation for stats */
@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.stat-card {
    animation: slideUp 0.5s ease-out forwards;
}

.stat-card:nth-child(2) {
    animation-delay: 0.2s;
}

.stat-card:nth-child(3) {
    animation-delay: 0.4s;
}

.referral-tools {
    display: grid;
    gap: 30px;
    margin-top: 20px;
}

.qr-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.download-qr-button {
    padding: 10px 20px;
    background-color: var(--primary-main);
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.download-qr-button:hover {
    background-color: var(--primary-dark);
}

.share-buttons {
    text-align: center;
    padding: 20px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.share-buttons h3 {
    margin-bottom: 15px;
    color: var(--text-primary);
}

.share-options {
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
}

.share-button {
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
}

.share-button.telegram {
    background-color: #0088cc;
    color: white;
}

.share-button.whatsapp {
    background-color: #25D366;
    color: white;
}

.share-button.email {
    background-color: #EA4335;
    color: white;
}

.share-button:hover {
    transform: translateY(-2px);
}

.marketing-tips {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.marketing-tips h3 {
    color: var(--text-primary);
    margin-bottom: 15px;
}

.marketing-tips ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.marketing-tips li {
    padding: 10px 25px;
    position: relative;
    color: var(--text-secondary);
}

.marketing-tips li::before {
    content: '💡';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

@media (max-width: 768px) {
    .share-options {
        flex-direction: column;
    }

    .share-button {
        width: 100%;
        justify-content: center;
    }

    .qr-code-container canvas {
        width: 100%;
        height: auto;
    }
} 