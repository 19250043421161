.affiliate-login {
    max-width: 500px;
    margin: 40px auto;
    padding: 20px;
}

.login-container {
    background-color: white;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
}

.login-tabs button {
    flex: 1;
    padding: 12px;
    border: none;
    background: none;
    border-bottom: 2px solid var(--border-main);
    color: var(--text-secondary);
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.login-tabs button.active {
    color: var(--primary-main);
    border-bottom-color: var(--primary-main);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: var(--text-primary);
    font-weight: 500;
}

.form-group input {
    width: 100%;
    padding: 12px;
    border: 2px solid var(--border-main);
    border-radius: 8px;
    font-family: inherit;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.form-group input:focus {
    border-color: var(--primary-main);
    outline: none;
    box-shadow: 0 0 0 3px rgba(43, 108, 176, 0.1);
}

.login-container button[type="submit"] {
    width: 100%;
    padding: 14px;
    background-color: var(--primary-main);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.login-container button[type="submit"]:hover {
    background-color: var(--primary-dark);
    transform: translateY(-2px);
}

/* Dashboard styles */
.affiliate-dashboard {
    max-width: 1000px;
    margin: 40px auto;
    padding: 20px;
}

.affiliate-dashboard h2 {
    text-align: center;
    margin-bottom: 30px;
    color: var(--text-primary);
}

.dashboard-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 40px;
}

.stat-item {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.stat-label {
    color: var(--text-secondary);
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.stat-value {
    color: var(--primary-main);
    font-size: 1.5rem;
    font-weight: bold;
}

.referrals-list {
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.referrals-list h3 {
    margin-bottom: 20px;
    color: var(--text-primary);
}

.referrals-list table {
    width: 100%;
    border-collapse: collapse;
}

.referrals-list th,
.referrals-list td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid var(--border-main);
}

.referrals-list th {
    background-color: var(--background-default);
    font-weight: 600;
    color: var(--text-primary);
}

.referrals-list tr:last-child td {
    border-bottom: none;
}

@media (max-width: 768px) {
    .affiliate-login {
        margin: 20px auto;
        padding: 15px;
    }

    .login-container {
        padding: 20px;
    }

    .affiliate-dashboard {
        margin: 20px auto;
        padding: 15px;
    }

    .dashboard-stats {
        grid-template-columns: 1fr;
    }

    .referrals-list {
        overflow-x: auto;
    }

    .referrals-list table {
        min-width: 600px;
    }
} 