.admin-summary {
    padding: 20px;
    max-width: 1400px;
    margin: 0 auto;
}

.summary-filters {
    margin-bottom: 20px;
}

.summary-filters select {
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid var(--border-main);
}

.summary-table-container {
    overflow-x: auto;
}

.summary-table {
    width: 100%;
    border-collapse: collapse;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.summary-table th,
.summary-table td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid var(--border-light);
}

.summary-table th {
    background-color: var(--primary-main);
    color: white;
    font-weight: 600;
}

.summary-table tr:last-child td {
    border-bottom: none;
}

.summary-table tr:hover {
    background-color: var(--background-default);
}

.pay-button {
    padding: 6px 12px;
    background-color: var(--success-main);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.pay-button:hover {
    background-color: var(--success-dark);
}

@media (max-width: 768px) {
    .summary-table-container {
        margin: 0 -20px;
        padding: 0 20px;
    }
    
    .summary-table {
        font-size: 0.9rem;
    }
} 